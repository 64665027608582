(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/co-op-play/assets/javascripts/co-op-result-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/co-op-play/assets/javascripts/co-op-result-selectors.js');
"use strict";


const {
  PAGE_SIZE
} = svs.components.marketplaceShared.sharedConstants.constants;
const selectSearchFilters = state => state.CoOpPlayFilter || {};
const selectGameResults = state => state.CoOpPlayResults || {};
const getCoOpPlayResults = state => {
  const {
    loading,
    data,
    other,
    follow
  } = state.CoOpPlayResults || {};
  return {
    loading,
    data,
    other: {
      error: other.error,
      hasMoreData: other.offset < other.totalCount,
      nextLoading: loading && other.offset > 0,
      nextLoadCount: Math.min(other.totalCount - other.offset, PAGE_SIZE),
      totalCount: other.totalCount
    },
    follow: {
      error: follow.error,
      hasMoreData: follow.offset < follow.totalCount,
      nextLoading: loading && follow.offset > 0,
      nextLoadCount: Math.min(other.totalCount - other.offset, PAGE_SIZE),
      totalCount: follow.totalCount
    }
  };
};
setGlobal('svs.components.marketplaceShared.coOpPlay.selectors', {
  getCoOpPlayResults,
  selectSearchFilters,
  selectGameResults
});

 })(window);